<template>
  <div>
    <div>
      <div class="big">
        <div
          class="nav flex flex-x-y"
          :style="{
            'background-color': navBg || opacity,
            'box-shadow': op >= 1 ? shadow : '',
          }"
        >
          <div class="flex flex-x-b ml-130 mr-130 main-w">
            <div class="logo" @click="to('/index')">
              <img src="@/assets/logo.png" alt="" class="logo-img" />
              <span class="nowrap pointer">探奥科技</span>
            </div>
            <div class="route">
              <div>
                <router-link to="/index" class="route-item">
                  <span class="nowrap">首页</span>
                </router-link>
              </div>
              <div>
                <router-link to="/product" class="route-item">
                  <span class="nowrap">产品中心</span>
                </router-link>
              </div>
              <div>
                <router-link to="/solution" class="route-item"
                  ><span class="nowrap">解决方案</span>
                </router-link>
              </div>
              <div>
                <router-link to="/news" class="route-item"
                  ><span class="nowrap">新闻资讯</span>
                </router-link>
              </div>
              <div>
                <router-link to="/about" class="route-item">
                  <span class="nowrap">关于我们</span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="small">
        <div class="nav center flex flex-x-b"
        :style="{
            'background-color': navBg || opacity,
            'box-shadow': op >= 1 ? shadow : '',
          }"
        >
          <div class="logo" @click="to('/index')">
            <img src="@/assets/logo.png" alt="" class="logo-img" />
            <span class="nowrap pointer">探奥科技</span>
          </div>
          <div class="links">

          
          <el-dropdown >
            <!-- <span class="el-dropdown-link nowrap pointer logo">
              <img src="@/assets/logo.png" class="logo-img" alt="" />
              探奥科技
            </span> -->
            <span class="iconfont icon-liebiao main-color"></span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <router-link to="/index" class="route-item">
                  <span class="nowrap">首页</span>
                </router-link></el-dropdown-item
              >
              <el-dropdown-item
                ><router-link to="/product" class="route-item">
                  <span class="nowrap">产品中心</span>
                </router-link></el-dropdown-item
              >
              <el-dropdown-item
                ><router-link to="/solution" class="route-item"
                  ><span class="nowrap">解决方案</span>
                </router-link></el-dropdown-item
              >
              <el-dropdown-item
                ><router-link to="/news" class="route-item"
                  ><span class="nowrap">新闻资讯</span>
                </router-link></el-dropdown-item
              >
              <el-dropdown-item
                ><router-link to="/about" class="route-item">
                  <span class="nowrap">关于我们</span>
                </router-link></el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        </div>
      </div>
    </div>

    <router-view></router-view>

    <div class="footer color-FCFAFA fs-14">
      <div class="main-w foot-box">
        <div class="foot-info">
          <div class="foot-left flex">
            <span class="iconfont icon-kefu mt-20"></span>
            <div class="fs-14 ml-25">
              <p class="nowrap">咨询热线</p>
              <p v-if="info" class="nowrap">{{ info.tel }}</p>
            </div>
          </div>
          <div class="foot-right flex flex-wrap">
            <div class="foot-item">
              <p class="nowrap">服务标准</p>
              <div class="foot-line"></div>
              <p class="pointer" @click="to('/about')">关于我们</p>
              <p class="pointer" @click="to('/solution')">服务介绍</p>
              <p class="pointer" @click="to('/solution')">价值标准</p>
            </div>
            <div class="foot-item">
              <p class="nowrap">产品应用</p>
              <div class="foot-line"></div>
              <p class="pointer" @click="to('/product')">产品介绍</p>
              <p class="pointer" @click="to('/product')">合作案例</p>
              <p class="pointer" @click="to('/product')">应用场景</p>
            </div>
            <div class="foot-item">
              <p class="nowrap">更多</p>
              <div class="foot-line"></div>
              <p class="pointer" @click="to('/news')">新闻中心</p>
              <p class="pointer" @click="to('/about')">联系我们</p>
            </div>
            <div class="flex flex-d-c" v-if="info">
              <p class="nowrap">联系我们</p>
              <div class="foot-line"></div>
              <p class="nowrap">咨询电话：{{ info.tel }}</p>
              <p class="">公司地址：{{ info.set }}</p>
            </div>
          </div>
        </div>

        <div class="end center">
          <!-- <div class="foot-line"></div> -->
          <span>Copyright © 2022 杭州探奥科技有限公司</span> 
          <a href="https://beian.miit.gov.cn" target="_blank" style="color:#ffffff !important; text-decoration: none;margin-left: 10px;">{{ info.beian }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { info } from "@/api/tanao";
export default {
  name: "layout",
  data() {
    return {
      hasBg: false,
      opacity: "",
      shadow: "0 3px 3px rgba(255, 255, 255, 0.1)",
      op: 0,
      screenWidth: 0,
    };
  },
  computed: {
    navBg() {
      return this.$store.state.navBg;
    },
    info() {
      return this.$store.state.info;
    },
  },
  created() {
    // info().then((res) => {
    //   console.log(res, 'res')
    //   this.info = res.data
    // })
  },
  mounted() {
    // console.log(document.body, "11111");
    window.addEventListener("scroll", (event) => {
      let scrollTop = this.getScroll().top;
      // console.log(event);
      if (scrollTop < 500) {
        this.hasBg = false;
      } else {
        this.hasBg = true;

        let op = (scrollTop - 500) / 500;
        if (op >= 0.9) {
          op = 0.9;
        }
        this.op = op;
        this.opacity = `rgba(51, 51, 51, ${this.op})`;
      }
    });
  },
  methods: {
    getScroll() {
      return {
        left:
          window.pageXOffset ||
          document.documentElement.scrollLeft ||
          document.body.scrollLeft ||
          0,
        top:
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop ||
          0,
      };
    },
    to(page) {
      this.$router.push({ path: page });
    },

  },
};
</script>

<style lang="scss" scoped>
a {
  color: #595959;
  text-decoration: none;
}
@media screen and (max-width: 768px) {
  //小屏幕
  .small {
    display: block;
  }
  .big {
    display: none;
  }
  .footer {
    width: 100%;
    // height: 472px;
    font-size: 10px;
    margin: 0 auto;
    background-color: #333333;
  }
  .foot-box {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
  }

  .foot-left {
    width: 100%;
    margin: 0;
    .icon-kefu {
      font-size: 25px;
    }
  }
  .foot-right {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0;
  }
  .foot-item {
    width: 25%;
    margin-right: 20px;
    .foot-line {
      height: 0.5px;
      width: 65%;
      background-color: #4e4e4e;
    }
  }
  .foot-line {
    height: 0.5px;
    width: 100%;
    background-color: #4e4e4e;
  }
  .end {
    width: 100%;
    border-top: 1px solid #595959;
    padding-top: 5px;
    text-align: center;
    margin: 5px;
  }
}
@media screen and (min-width: 768px) {
  //大屏幕
  .small {
    display: none;
  }
  .big {
    display: block;
  }
  .footer {
    width: 100%;
    margin: 0 auto;
    background-color: #333333;
  }
  .foot-box {
    display: flex;
    flex-direction: column;
    padding-top: 5%;
    margin-bottom: 0;
  }
  .foot-info {
    display: flex;
  }
  .foot-left {
    width: 200px;
    .icon-kefu {
      font-size: 32px;
    }
  }
  .foot-right {
    width: 1000px;
    margin-left: 60px;
  }
  .foot-item {
    display: flex;
    flex-direction: column;
    margin-right: 80px;
  }
  .foot-line {
    height: 1px;
    width: 100%;
    background-color: #4e4e4e;
  }
  .end {
    border-top: 1px solid #4e4e4e;
    line-height: 5;
  }
}
.small {
  .nav {
    z-index: 999;
    position: fixed;
    top: 0;
    left: 0px;
    height: 50px;
    width: 100%;
    // background-color: blue;
    // height: 45px;
  }
  .logo-img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
  .logo {
    // position: fixed;
    // left: 20px;
    // top: 15px;
    display: flex;
    align-items: center;
    font-size: 20px;
    color: #fff;
    margin-left: 20px;
  }
  .links {
    line-height: 50px;
    margin-right: 20px;
  }
}
.big {
  .nav {
    height: 70px;
    width: 100%;
    /* background-color: lightblue; */
    display: flex;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
  }
  .route {
    // margin-left: 224px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .router-link-active {
    position: relative;
  }

  .router-link-active::after {
    content: "";
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translate(-50%);
    width: 16px;
    height: 8px;
    background: url("@/assets/current.png") left top/100% 100% no-repeat;
  }

  .route-item {
    margin-right: 35px;
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.logo-img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
.logo {
  display: flex;
  align-items: center;
  font-size: 20px;
  color: #fff;
}

// @media screen and (max-width: 767px) {
//   .nav {
//   }
// }
</style>