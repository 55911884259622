import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import  './common/common.css'
import  './common/iconfont.css'
import request from './utils/request'

import {
  Carousel,
  Image,
  CarouselItem,
  Col,
  Card,
  Input, 
  Button,
  Alert,
  MessageBox,
  Tabs,
  TabPane,
  Pagination,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  MenuItem,
  Submenu
} from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
import '@/common/element-variables.scss'

Vue.use(Carousel)
Vue.use(Image)
Vue.use(CarouselItem)
Vue.use(Col)
Vue.use(Card)
Vue.use(Input)
Vue.use(Button)
Vue.use(Alert)
Vue.use(TabPane)
Vue.use(Tabs)
Vue.use(Pagination)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Dropdown)
Vue.use(MenuItem)
Vue.use(Submenu)
Vue.component(MessageBox.name, MessageBox)


// Vue.prototype.$message = Message
Vue.prototype.$mb = MessageBox
Vue.config.productionTip = false
Vue.prototype.request = request

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
