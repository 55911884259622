import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../Layout'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Layout,
    redirect: '/index',
    children: [{
      path:'/index',

      component:()=>import('../views/index.vue')
    },
    {
      path:'/about',
      component:()=>import('../views/about.vue')
    },
    {
      path:'/news',
      component:()=>import('../views/news.vue')
    },
    {
      path:'/product',
      component:()=>import('../views/product.vue')
    },
    {
      path:'/solution',
      component:()=>import('../views/solution.vue')
    },
    {
      path:'/detail',
      component:()=>import('../views/detail.vue')
    },
  ]
}
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
