import request from '@/utils/request'

//首页轮播
export function  banner()  {
    return request({
        url:'/banner/list',
    })
}
//新闻列表
export function news(params) {
    console.log(params, 'paramssss')
    return request({
        url: '/news/list',
        params,
    })
}
//联系我们
export function contact(data) {
    return request({
        url:'/contact/add',
        method: 'post',
        data,
    })
}
//合作伙伴
export function cooperative() {
    return request({
        url: '/cooperative/list',
    })
}
//公司信息列表
export function info() {
    return request({
        url: '/info/list',
    })
}
//产品案例
export function product() {
    return request({
        url: '/product/list',
    })
}