<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {
    this.$store.dispatch('getInfo')
  }
};
</script>
<style lang="scss">
/* 改变主题色变量 */
$--color-primary: #42b983;

/* 改变 icon 字体路径变量，必需 */
$--font-path: "~element-ui/lib/theme-chalk/fonts";

:export {
  colorPrimary: $--color-primary;
}
</style>
